"use client"

import { useCallback, useRef, useState } from 'react'
import Link from 'next/link'
import resolveIcon from '@utils/styles/resolveIcon'
import resolveColors from '@utils/styles/resolveColors'
import mix from '@utils/styles/mix'

import styles from './Button.module.css'

const COLORS = {
	blue: 'blue',
	gray: 'gray',
	green: 'green',
	red: 'red',
	teal: 'teal',
	white: 'white',
	yellow: 'yellow',
}

function Button({
	active,
	debounce,
	children,
	className,
	href,
	icon,
	nowrap,
	onClick,
	...rest
}) {
	const [colors, otherProps] = resolveColors(rest, COLORS, styles)
	const [pending, setPending] = useState(false)
	const timeoutRef = useRef()

	const isDisabled =
		pending || (typeof active === 'boolean' && active === false)

	const _className = mix([
		styles.button,
		className,
		colors,
		isDisabled && styles.disabled,
		nowrap && styles.nowrap,
	])

	const _onClick = useCallback(
		(e) => {
			if (pending) {
				if (e) {
					e.preventDefault()
					e.stopPropagation()
				}
				return
			}

			if (debounce > 0) {
				clearTimeout(timeoutRef.current)
				timeoutRef.current = setTimeout(
					() => setPending(false),
					debounce,
				)
				setPending(true)
			}

			if (onClick) onClick(e)
		},
		[debounce, onClick, pending, setPending],
	)

	return href ? (
		(<Link
        href={href}
        {...otherProps}
        className={_className}
        onClick={_onClick}
    	>
			<>
				{resolveIcon(icon)}
				{children}
			</>
        </Link>)
	) : (
		<button {...otherProps} className={_className} onClick={_onClick}>
			{resolveIcon(icon)}
			{children}
		</button>
	);
}

Button.COLORS = COLORS

export default Button
